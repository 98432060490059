import { GiftIcon, IConArrowDown, SharedButton, SharedImage, Trash } from '@components';
import {
  DEFAULT_CLASSIFICATION_PARAMS,
  DEFAULT_SUB_CLASSIFICATION_PARAMS,
  MAIN_THEME_DATA,
  testImg,
} from '@configs';
import { LogApp, generateRandomId, toVND } from '@utils';
import { Dropdown, Tooltip } from 'antd';
import { CSSProperties, useRef, useState } from 'react';
import { QuantityControl } from 'src/components/product/components/QuantityControl';
import { Size } from 'src/components/product/components/Size';
import { styled } from 'styled-components';
import {
  CartItem as CartItemInterface,
  Classification,
  PromotionType,
  RootResponse,
  SubClassification,
} from '@interfaces';
import { productAPI } from '@api';
import { useImmer } from 'use-immer';
//@ts-ignore
import { NotificationManager } from 'react-notifications';
import { debounce } from 'lodash';
const contentStyle: CSSProperties = {
  backgroundColor: 'white',
  borderRadius: 12,
  boxShadow: '0px 1px 16px rgba(56, 56, 56, 0.15)',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 24,
  width: '67.2rem',
};
const mobileContentStyle: CSSProperties = {
  backgroundColor: 'white',
  borderRadius: 12,
  boxShadow: '0px 1px 16px rgba(56, 56, 56, 0.15)',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 16,
  width: '35.8rem',
};
const contentBlock: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  marginTop: 12,
};
const btnBlock: CSSProperties = {
  display: 'flex',
  width: '100%',
};
const leftBtn: CSSProperties = {
  borderRadius: 8,
  marginRight: 24,
};
const borderRadiusBtn: CSSProperties = {
  borderRadius: 8,
};
interface CartItemProps {
  hasBottomLine?: boolean;
  attribute: string;
  onIncrease: (quantity: number) => void;
  onDecrease: (quantity: number) => void;
  onUpdateParentClassification: (
    cartItem: CartItemInterface,
    classification: Classification,
  ) => void;
  onUpdateChildClassification: (
    cartItem: CartItemInterface,
    classification: SubClassification,
  ) => void;
  cartItem: CartItemInterface;
  onDeleteCartItem: (item: CartItemInterface) => void;
  isMobile?: boolean;
  getDiscountPercent: (cartItem: CartItemInterface) => number;
  onChangeQuantity?: (q: number) => void;
}
export const CartItem = (props: CartItemProps) => {
  const {
    attribute,
    onUpdateParentClassification,
    onDecrease,
    onIncrease,
    hasBottomLine = true,
    cartItem,
    onUpdateChildClassification,
    onDeleteCartItem,
    isMobile,
    getDiscountPercent,
    onChangeQuantity,
  } = props;
  const {
    price,
    amount,
    image,
    name,
    selectedClassifications,
    classifications,
    deduct_percent,
    reward,
  } = cartItem;
  const [itemAmount, setItemAmount] = useState(amount);
  const [finalItemAmount, setFinalItemAmount] = useState(amount);
  const debounceTimer = useRef<any>(null);
  const classificationsRef = useRef<{
    classification: {
      parent: Classification;
      child: SubClassification;
    };
  }>({
    classification: {
      parent: DEFAULT_SUB_CLASSIFICATION_PARAMS,
      child: DEFAULT_SUB_CLASSIFICATION_PARAMS,
    },
  });
  const [opened, setOpened] = useState(false);
  const [selectedClasses, setSelectedClasses] = useImmer<{
    parent: Classification;
    child: SubClassification;
  }>({
    parent: DEFAULT_CLASSIFICATION_PARAMS,
    child: DEFAULT_SUB_CLASSIFICATION_PARAMS,
  });
  const [classificationData, setClassificationData] = useState<Classification[]>([]);
  const getClassification = async () => {
    try {
      const res: RootResponse<Classification[]> = await productAPI.getClassifications(
        cartItem.product_id,
      );
      const foundIndex = res.data.findIndex(
        (item) => item.id === (cartItem.classification_parent_id ?? cartItem.classification_id),
      );
      if (foundIndex !== -1) {
        setSelectedClasses((d) => {
          const parentItem = res.data[foundIndex];
          if (res.data.every((item) => item.sub_classifications.length === 0)) d.child = parentItem;
          else d.parent = parentItem;
          if (parentItem.sub_classifications) {
            const foundChildIndex = parentItem.sub_classifications.findIndex(
              (item) => item.id === cartItem.classification_id,
            );
            if (foundChildIndex !== -1)
              d.child = res.data[foundIndex].sub_classifications[foundChildIndex];
          }
        });
      }
      setClassificationData(res.data);
      setOpened(true);
    } catch (error) {
      LogApp(error, 'err');
    }
  };
  const onUpdate = () => {
    if (selectedClasses.parent.id !== '' && selectedClasses.child.id !== '') {
      onUpdateParentClassification(cartItem, selectedClasses.parent);
      onUpdateChildClassification(cartItem, selectedClasses.child);
    } else if (selectedClasses.parent.id !== '') {
      onUpdateParentClassification(cartItem, selectedClasses.parent);
    } else if (selectedClasses.child.id !== '') {
      onUpdateChildClassification(cartItem, selectedClasses.child);
    }
    setOpened(false);
  };
  const getPercent = () => {
    if (selectedClassifications.parent.id !== '') {
      if (selectedClassifications.child.id !== '')
        return selectedClassifications.child.product_item.deduct_percent;
      return selectedClassifications.parent.product_item.deduct_percent;
    }
    return deduct_percent;
  };
  const deductedPrice = (getPercent() / 100) * price;
  const discountedPrice =
    ((getDiscountPercent(cartItem) || 0) / 100) * (cartItem.price - deductedPrice);
  const finalPrice = cartItem.price - deductedPrice - discountedPrice;
  const parentClassification = classificationData?.[0];
  const childClassification = classificationData?.[0]?.sub_classifications?.[0];
  const getQuantity = (quantity: number) => {
    if (quantity <= 20) return 20;
    if (quantity >= 100) return 100;
    return quantity;
  };
  const isSingleClassification = () => {
    return classificationData.every((item) => item.sub_classifications.length === 0);
  };
  const handleIncreaseProductQuantity = () => {
    setItemAmount((prv) => prv + 1);
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      onIncrease(itemAmount + 1);
    }, 500);
  };
  const handleDecreaseProductQuantity = () => {
    setItemAmount((prv) => prv - 1);
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      onDecrease(itemAmount - 1);
    }, 500);
  };
  return (
    <StyledCartItem hasBottomLine={hasBottomLine}>
      <SharedImage containerClassName="image-wrapper" className="image" src={image?.url} />
      <div className="content-block">
        <div className="head-block">
          <span className="text-17-med name">{name}</span>
          <div className="price-block">
            <span className="text-17-med price">
              {toVND(cartItem.price_deducted * cartItem.amount)}
            </span>
          </div>
        </div>
        <span className="deducted">
          Đơn giá: <span className="price deducted">{toVND(cartItem.price)}</span>
        </span>
        <div className="flex flex-col w-full justify-between">
          {getPercent() > 0 && (
            <span className="deducted">
              Chiết khẩu: <span className="price deducted">{getPercent()}%</span>
            </span>
          )}
          {cartItem.promotions.length > 0 && (
            <span className="deducted">
              Khuyến mãi: <span className="price deducted">{getDiscountPercent(cartItem)}%</span>
            </span>
          )}
          <span className="deducted">
            Giá cuối cùng (bao gồm chiết khấu, khuyến mãi):{' '}
            <span className="price deducted">{toVND(cartItem.price_deducted)}</span>
          </span>
          {/* <Tooltip title='Sản phẩm này được tính vào doanh số thưởng'>
            {reward && <GiftIcon />}
          </Tooltip> */}
        </div>
        {!!cartItem.classification_id && (
          <Dropdown
            onOpenChange={(val) => {
              if (val) {
                getClassification();
              } else {
                setOpened(val);
              }
            }}
            open={opened}
            trigger={['click']}
            dropdownRender={(menu) => (
              <div style={isMobile ? mobileContentStyle : contentStyle}>
                <div>
                  <span>{parentClassification?.attribute_name}</span>
                  <div style={contentBlock}>
                    {classificationData.map((item) => {
                      return (
                        <Size
                          key={item.id}
                          borderColor="#888888"
                          isSelected={
                            isSingleClassification()
                              ? selectedClasses.child.id === item.id
                              : selectedClasses.parent?.id === item.id
                          }
                          onClick={() => {
                            setSelectedClasses((d) => {
                              if (isSingleClassification()) {
                                d.child = item;
                                d.parent = DEFAULT_CLASSIFICATION_PARAMS;
                              } else {
                                d.parent = item;
                                d.child = DEFAULT_SUB_CLASSIFICATION_PARAMS;
                              }
                            });
                          }}
                          hasQuantity={selectedClasses.parent?.sub_classifications.length === 0}
                          title={item.value}
                          quantity={getQuantity(item?.product_item?.quantity)}
                          percent={item?.product_item?.deduct_percent}
                        />
                      );
                    })}
                  </div>
                </div>
                <div>
                  <span>{childClassification?.attribute_name}</span>
                  <div style={contentBlock}>
                    {selectedClasses.parent?.sub_classifications?.map((item, index) => {
                      return (
                        <Size
                          isSelected={selectedClasses.child.id === item.id}
                          borderRadius="0.8rem"
                          borderColor="#888888"
                          key={item.id}
                          title={item.value}
                          hasQuantity={true}
                          quantity={getQuantity(item?.product_item?.quantity)}
                          onClick={() => {
                            setSelectedClasses((d) => {
                              d.child = item;
                            });
                          }}
                          percent={item?.product_item?.deduct_percent}
                        />
                      );
                    })}
                  </div>
                </div>
                <div>
                  <div style={btnBlock}>
                    <SharedButton
                      style={leftBtn}
                      textClassName="text-btn"
                      btnStyle="line"
                      textColor={MAIN_THEME_DATA.mainColor}
                      text="Hủy"
                      onClick={() => {
                        setOpened(false);
                      }}
                    />
                    <SharedButton
                      style={borderRadiusBtn}
                      text="Tiếp tục"
                      onClick={() => {
                        if (
                          selectedClasses.child.id === '' &&
                          selectedClasses.parent.sub_classifications.length > 0
                        )
                          NotificationManager.warning(
                            `Vui lòng chọn ${childClassification?.attribute_name}`,
                            'Thông báo',
                          );
                        else onUpdate();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          >
            <div className="dropdown-holder">
              <span className="text-14 attribute">{`Phân loại: ${attribute}`}</span>
              <IConArrowDown color={MAIN_THEME_DATA.mainColor} />
            </div>
          </Dropdown>
        )}
        <div className="foot-block">
          <QuantityControl
            hasTitle={false}
            size="small"
            quantity={itemAmount}
            onIncrease={handleIncreaseProductQuantity}
            onDecrease={handleDecreaseProductQuantity}
            onChangeQuantity={(q) => {
              onChangeQuantity?.(q);
              setItemAmount(q);
            }}
          />
          <Trash
            onClick={() => {
              onDeleteCartItem(cartItem);
            }}
            color="#D20C00"
          />
        </div>
      </div>
    </StyledCartItem>
  );
};
const StyledCartItem = styled.div<{ hasBottomLine?: boolean }>`
  display: flex;
  padding: 1.6rem;
  border-bottom-width: ${(p) => (p?.hasBottomLine ? '1px' : '0px')};
  border-bottom-color: rgb(52, 52, 52, 0.15);
  .price {
    color: #d20c00;
  }
  .dropdown-holder {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }
  .content-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .deducted {
      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .foot-block {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .head-block {
      display: flex;
      justify-content: space-between;
      .name {
        max-width: 63%;
      }
      .price-block {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
      }
    }
  }

  .image-wrapper {
    width: unset;
    margin-right: 1.6rem;
  }
  .image {
    width: 13.6rem;
    aspect-ratio: 1;
    border-radius: 10px;
  }
  .text-17-med {
    font-family: Roboto-Medium;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    @media (max-width: 768px) {
      font-size: 1.4rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .text-14 {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  .original-price {
    text-decoration-line: line-through;
  }
  .attribute {
    color: #d20c00;
    margin-top: 0.8rem;
  }
`;
