import { memo, useCallback, useEffect, useState } from 'react';

import { AppHeader } from '@components';
import {
  PATH_CART,
  PATH_CATEGORY,
  PATH_HOME,
  PATH_LOGIN,
  PATH_PRODUCT,
  PATH_PROFILE,
  PATH_SEARCH,
  WEB_NAV_BAR,
} from '@configs';
import {
  getUserInfo,
  selectAuth,
  selectUser,
  setAccessToken,
  showUnAuthModal,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getCategories,
  setCurrentChildCategory,
  setCurrentParentCategory,
  getWarehouses,
  setWarehouseModalOpened,
  setCurrentWarehouse,
  selectHome,
  getProductsByKeyword,
  setCurrentProductSearchKey,
  setCurrentProductSearchPage,
  setCategoryPage,
} from 'src/redux/Slices/home';
import {
  Category,
  ProductFilter,
  ProductFilterResponse,
  ProductFilterRoot,
  SubCategory,
  WarehouseItem,
} from '@interfaces';
import { homeAPI } from '@api';
import { debounce } from 'lodash';
import { LogApp } from '@utils';

interface IProps {
  isAuth?: boolean;
}

export const HeaderModule = memo((props: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { privateId } = useAppSelector(selectAuth);
  const { accessToken } = useAppSelector(selectAuth);
  const userState = useAppSelector(selectUser);
  const avatar = userState?.avatar;
  const { warehouseModal, warehouses, currentWarehouse } = useAppSelector(selectHome);
  const [open, setOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const [keyword, setKeyword] = useState('');
  const [warehouseKeyword, setWarehouseKeyword] = useState('');
  const warehouseId = currentWarehouse?.id;
  const onSearchWarehouse = debounce((value: string) => {
    setWarehouseKeyword(value);
  }, 600);
  const onSelectWarehouse = (item: WarehouseItem) => {
    dispatch(setCurrentWarehouse(item));
  };
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };
  const handleProductOpenChange = (flag: boolean) => {
    setProductOpen(flag);
  };
  const onNavItemClick = (path: string) => {
    if (!accessToken && path !== PATH_HOME) dispatch(showUnAuthModal(true));
    else navigate(path);
  };
  const onNavigateToCategory = (item: Category) => {
    dispatch(setCurrentParentCategory(item));
    dispatch(setCategoryPage(0));
    navigate(PATH_CATEGORY + `/${item.id}`);
  };
  const onClickChildCategory = (item: SubCategory) => {
    dispatch(setCurrentChildCategory(item));
    dispatch(setCategoryPage(0));
  };
  const getProductByKeyword = async (text: string) => {
    if (!text) return;
    try {
      const res: any = await homeAPI.searchProduct({
        keyword: text,
        page: 0,
        warehouse_id: warehouseId,
      });
      setProducts(res.data);
      handleProductOpenChange(true);
    } catch (error) {
    } finally {
      LogApp('a');
    }
  };
  const onSearch = (value: string) => {
    setKeyword(value);
  };
  LogApp(window.location.href, 'location');

  const onNavigateToProductDetail = (item: ProductFilter) => {
    handleProductOpenChange(false);
    navigate(`${PATH_PRODUCT}/${item.code}`);
    const category = item.categories?.[0];
    const subCategory = category?.sub_categories?.[0];
    dispatch(setCurrentParentCategory(category));
    dispatch(setCurrentChildCategory(subCategory));
  };
  const handleSubmitSearch = () => {
    if (keyword.length === 0) {
      return;
    }
    if (location.pathname.includes(PATH_SEARCH)) {
      dispatch(
        getProductsByKeyword({
          page: 0,
          keyword,
          warehouse_id: warehouseId,
          size: 25,
        }),
      );
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      searchParams.set('key', keyword);
      url.search = searchParams.toString();
      window.history.pushState({}, '', url);
    } else {
      navigate(`${PATH_SEARCH}?key=${keyword}`);
    }
    dispatch(setCurrentProductSearchKey(keyword));
    dispatch(setCurrentProductSearchPage(0));
    handleProductOpenChange(false);
  };
  const onInputClick = (value: boolean) => {
    if (keyword && value) handleProductOpenChange(true);
    else handleProductOpenChange(false);
  };
  const debounceSearch = useCallback(
    debounce((text) => getProductByKeyword(text), 700),
    [warehouseId],
  );
  const navigateToProfile = () => {
    navigate(PATH_PROFILE);
  };
  const navigateToCart = () => {
    navigate(PATH_CART);
  };
  const onOpenWarehouseModal = (value: boolean) => {
    dispatch(setWarehouseModalOpened(value));
  };
  useEffect(() => {
    if (keyword) {
      debounceSearch(keyword);
    }
  }, [keyword, warehouseId]);
  useEffect(() => {
    setKeyword('');
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getWarehouses(warehouseKeyword));
  }, [warehouseKeyword]);
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getUserInfo(privateId.userId));
  }, []);
  useEffect(() => {
    getProductByKeyword(keyword);
  }, []);
  useEffect(() => {
    setKeyword('');
  }, [location.pathname]);
  return (
    <AppHeader
      keyword={keyword}
      handleSubmitSearch={handleSubmitSearch}
      onClickChildCategory={onClickChildCategory}
      navItems={WEB_NAV_BAR}
      onNavItemClick={onNavItemClick}
      onNavigateToCategory={onNavigateToCategory}
      handleOpenChange={handleOpenChange}
      handleProductOpenChange={onInputClick}
      open={open}
      productOpen={productOpen}
      navigateToProfile={navigateToProfile}
      onSearch={onSearch}
      products={products}
      onNavigateToProductDetail={onNavigateToProductDetail}
      onOpenWarehouseModal={onOpenWarehouseModal}
      warehouses={warehouses}
      warehouseModal={warehouseModal}
      onSelectWarehouse={onSelectWarehouse}
      currentWarehouse={currentWarehouse}
      onSearchWarehouse={onSearchWarehouse}
      avatar={avatar}
      navigateToCart={navigateToCart}
      {...props}
    />
  );
});
